.mainDivStyle {
    display: grid;
    margin-top: 10px;
    margin-bottom: 5px;
}

.picker {
    border: 1px solid grey;
}

.timePicker {
    margin-bottom: 5px;
}