.policyNumberStyling {
    font-weight: 400 !important;
    margin-bottom: 7px !important;
}

.marginBottom {
    margin-bottom: 7px;
}

.topBottomPadding {
    padding: 10px 0px;
}

.dropdownStyle {
    width: 68%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.phoneDropdownStyle {
    width: 90%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.topBottomMargin {
    margin: 10px 0px !important;
    font-weight: 400 !important;
}