.containerMargins {
    margin: 10px 0px;
}

.uploadDocumentParagraph {
    margin: 10px 0px !important;
}

.repairCost {
    width: 55%;
}

.phoneRepairCost {
    width: 80%;
}

.estimateContainer {
    margin: 10px 0px;
}

.repairCostWithMargin {
    width: 55%;
    margin-bottom: 10px;
}

.phoneRepairCostWithMargin {
    width: 80%;
    margin-bottom: 10px;
}

.bottomMargin {
    margin-bottom: 15px !important;
}