
.lightColor {
    color: var(--NN-TITLE-FIRST-COLOR);
    font-size: var(--GW-FONT-SIZE-H1);
    line-height: var(--GW-LINE-HEIGHT-H1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.mediumColor {
    color: var(--NN-TITLE-SECOND-COLOR);
    font-size: var(--GW-FONT-SIZE-H1);
    line-height: var(--GW-LINE-HEIGHT-H1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.darkColor {
    color: var(--NN-TITLE-THIRD-COLOR);
    font-size: var(--GW-FONT-SIZE-H1);
    line-height: var(--GW-LINE-HEIGHT-H1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}