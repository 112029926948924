@import "../../styles/page.scss";
// item container
.flexNoColor {
    display: flex;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid lightgray;
    color: var(--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-TEXT-COLOR);
}

.flexWithColor {
    display: flex;
    margin-bottom: 10px;
    background-color: var(--GW-BRAND-COLOR-1);
    border-radius: 15px;
    border: 1px solid lightgray;
    color: var(--NN-SELECTABLE-POLICY-SELECTED-POLICY-TEXT-COLOR);
}

// Image Lob section
.imageContainer {
    padding: 5px;
    max-width: 15%;
    display: flex;
    align-items: center;

    @include gw-breakpoint-only(phone) {
        max-width: 25%;
    }
}

.image {
    border-radius: 15px;
}

// Container Body section
.bodyContainer {
    flex-grow: 3;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.unselectedItemHeaderText {
    color: var(--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-HEADER-TEXT-COLOR) !important;
}

.selectedItemHeaderText {
    color: var(--NN-SELECTABLE-POLICY-SELECTED-POLICY-HEADER-TEXT-COLOR) !important;
}

