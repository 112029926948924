@import "~@jutro/theme/assets/sass/helpers";

.gwQuoteTable {
    --gw-quote-table-border: 1px solid var(--GW-BORDER-COLOR);

    @include gw-breakpoint-down(phone) {
        padding: 0;
        & > div {
            border: var(--gw-quote-table-border);
            border-bottom: none;

            &:last-child {
                border-bottom: var(--gw-quote-table-border);
            }
        }
    }

    & > div:first-child {
        border-right: var(--gw-quote-table-border);
        border-left: var(--gw-quote-table-border);
        border-top: none;
        border-bottom: none;
        background-color: var(--GW-BACKGROUND-COLOR-BODY);
        font-weight: var(--GW-FONT-SIZE-H5);
        padding: var(--GW-SPACING-2);
    }

    table {
        background-color: var(--GW-BACKGROUND-COLOR);

        thead, th {
            display: none;
        }

        tr {
            border: none;

            &:hover {
                background-color: unset;
            }
        }
    }
}

td.gwTableCell {
    --table-padding-y: 0;
    --table-padding-x: 0;
    --table-cell-border-width: 1px;
    --table-border-color: var(--GW-BORDER-COLOR);
    --table-cell-width: 200px;

    width: var(--table-cell-width);
    vertical-align: top;
    border: var(--gw-quote-table-border);
}

.accordionCardQuote {
    margin: 0;
}

.quoteContainer {
    margin-bottom: var(--GW-SPACING-3);
}
