.divider {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    border-top: solid 3px var(--GW-BRAND-COLOR-1) !important;
}

.damagePickerAndBoxesContainer {
    display: flex;    
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;    
}

.damagePickerContainer {
    order: 1;
    margin-right: 30px;
}

.damageCheckboxesRightContainer {
    order: 2;
    flex-grow: 3;
}

.marginTopContainer {
    width: 40%;
    margin-right: 10% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.topMargin20Px {
    margin-top: 25px !important;
}

.topMargin {
    margin-top: 13px !important;
}

.bottomMargin {
    margin-bottom: 5px !important;
}

.flexContainer {
    display: flex;
}

.leftMargin {
    margin-left: 50px;
}

.phoneInputContainer {
    width: 80%;
    margin: 7px 0px;
}

