.dragDropDivNotDragging {
    border: 2px dashed grey;    
    margin-top: 10px;
}

.dragDropDivOnDrag {
    border: 2px dashed var(--GW-BRAND-COLOR-1);    
    margin-top: 10px;
}

