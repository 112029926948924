.wizardSidebarWrapper {
  padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
  text-align: left;
  background-color: var(--GW-BACKGROUND-COLOR);
  border-radius: var(--GW-SPACING-1);
  box-shadow: var(--GW-SHADOW-2);
}

.step {
  display: block;
  cursor: pointer;
  margin-bottom: var(--GW-SPACING-1);
  border-radius: var(--GW-SPACING-1);

  &.step:hover {
    text-decoration: var(--GW-TEXT-DECORATION-UNDERLINE);
  }

  &.active {
    color: var(--GW-COLOR-INFO-CONTRAST);
    background-color: var(--GW-BRAND-COLOR-1);
    position: relative;

    &.active:hover {
      text-decoration: var(--GW-NO-TEXT-DECORATION);
    }
  }

  &.active::after {
    content: "";
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-right: var(--GW-SPACING-3) solid var(--GW-COLOR-INFO-CONTRAST);
    border-bottom: var(--GW-SPACING-3) solid transparent;
    border-top: var(--GW-SPACING-3) solid transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.notVisited {
    color:  var(--GW-SEPARATOR-COLOR);
    cursor: not-allowed;

    .navigationButton {
      pointer-events: none;
    }

    &.notVisited:hover {
      text-decoration: var(--GW-NO-TEXT-DECORATION);
    }
  }

  .navigationButton {
    border: none;
    background: inherit;
    text-align: inherit;
    color: inherit;
    font: inherit;
    cursor: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--GW-FONT-SIZE-BODY);
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    line-height: var(--GW-LINE-HEIGHT-BODY);
    padding: var(--GW-SPACING-1) var(--GW-SPACING-2);
    border-radius: var(--GW-SPACING-1);

    &.navigationButton:focus{
      outline: none;
    }
  }
}

.warningIssue {
  color: var(--GW-COLOR-WARNING);
}

.errorIssue {
  color: var(--GW-COLOR-ERROR);
}

.stepsNav {
  list-style: none;
  text-align: left;
  padding: 0;
}

ul[class*='stepsNav'] {
  margin: 0;
}

.sideBarTitle {
  font-size: var(--GW-FONT-SIZE-H4);
  line-height: var(--GW-LINE-HEIGHT-H4);
  font-weight: bold;
  padding-bottom: var(--GW-SPACING-3);
  margin-top: var(--GW-SPACING-4);
  border-bottom: 1px solid var(--GW-BORDER-COLOR);
  display: block;
}

h4[class*='sideBarTitle'] {
  margin-bottom: var(--GW-SPACING-3);
}
