@import "~@jutro/theme/assets/sass/helpers";

.pageContainer {
    h2 {
        margin-top: 25px !important;
        margin-bottom: 5px !important;
    }
}

.divider {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    border-top: solid 3px var(--GW-BRAND-COLOR-1) !important;
}

.fieldInput {
    width: 48%;
    margin-top: 15px;

    @include gw-breakpoint-down(phone) {
        width: 80%;
    }
}

.dropdownFieldInput {
    width: 69.3%;
    margin-top: 15px;

    @include gw-breakpoint-down(phone) {
        width: 90%;
    }
}

.topMargin15px {
    margin-top: 15px;
}

.gridTwoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.flexContainer {
    display: flex;
    justify-content: space-between;

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}
