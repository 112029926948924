.additionalPartiesDiv {
    margin-top: 50px;
}

.addNewPartyButton {
    border: 2px dashed lightgray !important;
    padding: 20px;
    justify-content: flex-start;
    color: black;
    font-size: 1rem;
    margin-top: 20px;
}

.detailsContainer {
    border: 2px solid var(--GW-BRAND-COLOR-1);
    margin-bottom: 5px;
}

.containerHeaderDetails {
    font-size: 1.3rem;
    color: black;
}

.addMoreContacts {
    margin-bottom: 20px;
}