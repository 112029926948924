.divider {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    border-top: solid 3px var(--GW-BRAND-COLOR-1) !important;
}

.marginTopContainer {
    width: 40%;
    margin-right: 10% !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.pickerContainer {
    width: 54%;
    margin-bottom: 10px;
}

.topMargin20Px {
    margin-top: 25px !important;
}

.topMargin {
    margin-top: 13px !important;
}

.bottomMargin {
    margin-bottom: 5px !important;
}

.flexContainer {
    display: flex;
}

.flexContainerJustify {
    display: flex;
}

.leftMargin {
    margin-left: 50px;
}

.smallLeftMargin {
    margin-left: 30px;
    margin-bottom: 15px;
}

.phoneInputContainer {
    width: 80%;
    margin: 7px 0px;
}

.phonePickerContainer {
    width: 80%;
    margin-bottom: 10px;
}

.pageContainer {
    h2 {
        margin-top: 25px !important;
    }
}
