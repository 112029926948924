@import "~@jutro/theme/assets/sass/helpers";

.paymentToggle {
    --gw-quote-clause-payment-toggle-width: 50%;
    --gw-quote-clause-payment-toggle-margin: 30px;
    width: var(--gw-quote-clause-payment-toggle-width);
    margin: auto;
    margin-bottom: var(--gw-quote-clause-payment-toggle-margin);
    @include gw-breakpoint-down(phone) {
        width: 100%;
    }
}

.quoteTableHeader {
    position: sticky;
    top: 0;
    z-index: 101; // select icons have a z-index of 100
    display: flex;
    justify-content: space-around;
    border-bottom: 8px solid var(--GW-LINK-COLOR-ACTIVE);
    background-color: var(--GW-BACKGROUND-COLOR);
}

.quoteTableHeaderSection {
    --gw-quote-table-header-section-spacing: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--GW-BORDER-COLOR);
    padding: var(--gw-quote-table-header-section-spacing) 0 var(--gw-quote-table-header-section-spacing) 0;
    position: relative;

    &:not(:last-child) {
        border-right: none;
    }
}

.quotePriceDifference {
    --gw-quote-clause-table-position: 10px;
    --gw-price-difference-transition-time: 2.5s;
    background-color: var(--GW-COLOR-WARNING);
    position: absolute;
    top: var(--gw-quote-clause-table-position);
    left: var(--gw-quote-clause-table-position);
    animation: hidePriceDifference 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

@keyframes hidePriceDifference {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.paymentText {
    display: inline-block;
}

.quoteTitle {
    --gw-quote-title-margin-left: 0.25rem;
    text-align: center;
    color: var(--GW-TEXT-COLOR-1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    padding: 0;
    width: auto;
    margin-left: var(--gw-quote-title-margin-left);
}
.warningIconStyle {
    align-self: center;
    color: var(--GW-COLOR-WARNING);
}

.priceContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}

.quoteMonthlyCost {
    text-align: center;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    div[class*='CurrencyField'] {
        font-size: var(--GW-FONT-SIZE-H3);
    }
    padding: 0;
    width: auto;
}

.monthlyAmountText {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.gwQuoteTable {
    --gw-quote-table-border: 1px solid var(--GW-BORDER-COLOR);

    @include gw-breakpoint-down(phone) {
        & > div {
            border: var(--gw-quote-table-border);
            border-bottom: none;

            &:last-child {
                border-bottom: var(--gw-quote-table-border);
            }
        }
    }

    & > div:first-child {
        --gw-quote-table-title-bar-sizing: 8px;
        border-right: var(--gw-quote-table-border);
        border-left: var(--gw-quote-table-border);
        border-top: none;
        border-bottom: none;
        background-color: var(--GW-BACKGROUND-COLOR-HOVER);
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        padding: var(--gw-quote-table-title-bar-sizing);
    }

    table {
        background-color: var(--GW-BACKGROUND-COLOR);

        thead, th {
            display: none;
        }

        tr {
            border: none;

            &:hover {
                background-color: unset;
            }
        }
    }
}

td.gwTableCell {
    --table-padding-y: 0;
    --table-padding-x: 0;
    --table-cell-border-width: 1px;
    --table-border-color: var(--GW-BORDER-COLOR);
    --table-cell-width: 200px;

    width: var(--table-cell-width);
    vertical-align: top;
    border: var(--gw-quote-table-border);
}

// Phone styles
.accordionContainer {
    --gw-quote-table-phone-sizing: 15px;
    background-color: var(--GW-BACKGROUND-COLOR-HOVER);
}

.quoteContainer {
    --gw-quote-table-phone-margin: 10px;
    margin-bottom: var(--gw-quote-table-phone-margin);
}

.accordionStickyHeader {
    --gw-quote-table-border: 1px solid var(--GW-BORDER-COLOR);
    --gw-quote-table-phone-border-size: 3px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas: "quoteTitle quoteButton"
                                    "quotePrice quoteReset";
    grid-gap: var(--gw-quote-table-phone-sizing);
    padding: var(--gw-quote-table-phone-sizing);
    border: var(--gw-quote-table-border);
    border-bottom: var(--gw-blue-light-2) var(--gw-quote-table-phone-border-size) solid;
}

.mobileStickyHeader {
    position: sticky;
    top: 0;
    background-color: var(--GW-BACKGROUND-COLOR);
}

.accordionChevron {
    --chevron-margin-y: 0;
}

.accordionQuoteNameContainer {
    grid-area: quoteTitle;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.accordionQuotePriceContainer {
    grid-area: quotePrice;
}

.accordionButtonContainer {
    grid-area: quoteButton
}

.accordionResetContainer {
    grid-area: quoteReset
}

.accordionQuoteName {
    font-size: var(--GW-FONT-SIZE-H4);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    padding: 0;
}

.accordionQuotePrice {
    font-size: var(--GW-FONT-SIZE-H3);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);

    @include gw-breakpoint-down(phone) {
        width: auto;
    }
}

.selectedCoveragesContainer {
    padding: var(--gw-quote-table-phone-sizing);
}

.coverageContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.coverageCheck {
    --gw-quote-table-phone-check-margin: 5px;
    color: var(--GW-TEXT-COLOR-2);
    margin-right: var(--gw-quote-table-phone-check-margin);
}

.moreInfoContainer {
    border-top: 2px var(--GW-BORDER-COLOR) solid;
    padding: var(--gw-quote-table-phone-sizing);
}

.moreInfo {
    cursor: pointer;
    color: var(--GW-LINK-COLOR-ACTIVE);
}
.gwAlert{
	background-color: var(--GW-COLOR-WARNING);
	border: 1px solid var(--GW-COLOR-WARNING);
	padding: 16px;

	.referToUWFormActions {
		display: flex;
		justify-content: space-between;
	}

	.UWFormActions {
		display: flex;
		justify-content: flex-end;
	}
}

.accordionCardQuote {
    margin: 0;
}

.accordionCardQuoteTitle {
    border: none;
}