@import "../../styles/page.scss";

.pageContainer {
    @extend %pageContainer;
    max-width: 60%;
    // text-align: -webkit-center;

    @include gw-breakpoint-only(phone) {
        max-width: 90%;
    }
}

.componentContainer {
    @extend %componentContainer;
    width: 600px;
}

.inputFieldWidth {
    margin: 10px 0px;
    max-width: 50%;

    @include gw-breakpoint-only(phone) {
        max-width: 90%;
    }
}

// Assist Line label
.assistLineDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    @include gw-breakpoint-only(phone) {
        margin-top: 15px;
    }
}

.assistLineImageStyle {
    max-height: 30px;
    margin: 10px 0px 10px 12px;
}

// Next Button 
.nextPageButtonDiv {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.nextPageButton {
    min-width: 40%;

    @include gw-breakpoint-only(phone) {
        min-width: 100%;
    }
}

// Common styles

.sectionMargin {
    margin: 20px 0px !important;
}

.topBottomMargin {
    margin: 5px 0px !important;
}

.biggerTopMargin {
    margin-top: 30px;
    margin-bottom: 10px;
}

.topMargin {
    margin-top: 10px;
}