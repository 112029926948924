:root {
  --button-width: 230px;
  --icon-container-size: 100px;
  --max-width: 90em;
}

.gwKnockOutContainer {
  margin: 0 auto;
  max-width: var(--max-width);
  padding: 40px 0;
}

.gwKnockOutPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70%;
  height: 100%;
  margin: 0 auto 5px;
  padding: 0;
  line-height: var(--GW-FONT-SIZE-H3);
}

.gwKnockOutIcon {
  border: 1px solid var(--GW-COLOR-SUCCESS);
  color: var(--GW-COLOR-SUCCESS);
  width: var(--icon-container-size);
  height: var(--icon-container-size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--GW-FONT-SIZE-H1);
  margin: auto;
}

.gwKnockOutTitle {
  font-size: var(--GW-FONT-SIZE-H2);
  font-weight: var(--GW-FONT-WEIGHT-LIGHT);
  line-height: var(--GW-SPACING-8);
  margin-top: var(--GW-SPACING-8);
}

.gwKnockOutMoreInfo {
  margin-top: var(--GW-SPACING-8);
}

.gwKnockOutDescription {
  color: var(--GW-COLOR-WARNING);
  font-size: var(--GW-FONT-SIZE-H5);
  margin-top: var(--GW-SPACING-8);
}

.gwKnockOutButton {
  width: var(--button-width);
  margin: var(--GW-SPACING-8) auto 0;
}
