@import "~@jutro/theme/assets/sass/helpers";

.customHeaderStyle {
    background-color: var(--NN-HEADER-COLOR) !important;
    min-height: 70px !important;
}

.redBoldStyle {
    color:red !important;
    font-weight: var(--GW-FONT-WEIGHT-BOLD);

    @include gw-breakpoint-down(phone) {
        font-size: var(--GW-FONT-SIZE-H4) !important;
    }
}

.ingLinkStyles {
    text-decoration: auto;
    color: black;
    font-size: 12px;

    @include gw-breakpoint-down(phone) {
        font-size: 8px;
    }
}

.ingLogosStyle {
    max-height: 50px !important;
    min-height: 30px !important;
    min-width: 90px !important;
}
