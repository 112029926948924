@import "~@jutro/theme/assets/sass/helpers";

.marginBottom {
    margin-bottom: 5px;
    font-weight: 500;
    font-style: italic;
    max-width: 100vh;

    div {
        height: max-content !important;
    }

    @include gw-breakpoint-only(phone) {
        margin-bottom: 15px !important;
    }
}

.topBottomMargin {
    margin-bottom: 12px !important;
    padding: 0px 10px;
    align-items: baseline;
    max-width: 100vh;

    div {
        height: max-content !important;
    }

    @include gw-breakpoint-only(phone) {
        margin: 0px !important;
        padding: 0px !important;
        margin-top: 15px !important;
    }
}

.inlineMobileField {
    margin-bottom: 12px !important;
    padding: 0px 10px;
    align-items: baseline;
    max-width: 100vh;

    div {
        height: max-content !important;
    }

    @include gw-breakpoint-only(phone) {
        display: flex;
        margin: 0px !important;
        padding: 0px !important;

        div {
            display: contents;
        }
    }
}

.orangeHeader {
    color: var(--GW-BRAND-COLOR-1);
    font-weight: 700;
    margin-right: 5px;
    max-width: 100vh;
}

.blackMobileHeader {
    color: var(--GW-BRAND-COLOR-1);
    font-weight: 700;
    margin-right: 5px;
    max-width: 100vh;

    @include gw-breakpoint-only(phone) {
        color: black;
    }
}

.lobComponent {
    margin-bottom: 35px !important;
}