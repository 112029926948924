:root {
  --gw-payment-button-footer-width: 100%;
  --gw-payment-button-footer-margin-left: 1%;
}

.paymentButtonFooter {
  width: var(--gw-payment-button-footer-width);
  text-align: right;
  margin-top: var(--GW-SPACING-4);
}
.paymentButtonStyle {
  margin-left: var(--gw-payment-button-footer-margin-left);
}
.paymentCustomFooter {  
  display: flex;
  justify-content: space-between;
}