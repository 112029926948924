.loadingDivShow {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.8;
    display: block;
    background-color: #ddd;
    height: 100%;
    width: 100%;
    margin: auto;
    text-align: center;
    z-index: 910;    
}
.loadingDivHide {
    display: none;
    background-color: #ddd;
    z-index: 910;
}
