@import "~@jutro/theme/assets/sass/helpers";

%pageContainer {
    --gw-wizard-page-main-margin: 0 auto 4rem auto;

    display: flex;
    justify-content: center;
    margin: var(--gw-wizard-page-main-margin);
}

%componentContainer {
    margin-top: 30px;
    width: 480px;
}