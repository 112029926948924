@import "~@jutro/theme/assets/sass/helpers";

.imageStyle {
    width: 70px;
    height: auto;
}

.headerItem {
    align-items: center;
    justify-content: start;
}

.body {
    max-width: 80vw;
}

.grid {
    grid-template-columns: auto auto;

    @include gw-breakpoint-down(phone) {
        grid-template-columns: auto;
      }

}