// Typeahead
// ---------
.gw-typeahead {
  --gw-typeahead-zindex: 1051;
  --gw-typeahead-border-radius: 2px;
  --gw-digital-field-component-padding: 0;

  z-index: var(--gw-typeahead-zindex);
  border-radius: var(--gw-typeahead-border-radius);
  display: block;
  position: relative;

  &__results {
    display: block;
    right: 0;
  }

  &__match {
    cursor: pointer;
    padding: var(--GW-SPACING-2) calc(2 * var(--GW-SPACING-2));
    color: var(--GW-TEXT-COLOR-2);

    &_active {
      background: var(--GW-BACKGROUND-COLOR-ELEMENT-ACTIVE);
      color: var(--GW-TEXT-COLOR-1);
    }
  }

  &__highlight {
    color: var(--GW-COLOR-WARNING-CONTRAST);
  }

  &__wipeIcon,
  &__searchIcon {
    color: var(--GW-SEPARATOR-COLOR);
  }

  &__wipeIcon {
    cursor: pointer;

    &:hover {
      color: var(--GW-SEPARATOR-COLOR); //Only for clickable icon
    }
  }

  &__loader {
    display: block;
    height: 1px; //Should have some height for text flow purposes, but loader inside is bigger than 1 line, so want it to overflow without affecting height
    font-size: calc(var(--GW-SPACING-6) / 2);
  }

  &__loaderInner {
    transform: translateY(-50%);
  }

  &__selection {
    textarea {
      white-space: nowrap;
      overflow-x: hidden;
    }
  }
}

.icon {
  background-color: var(--GW-BACKGROUND-COLOR);
  border: none;
  color: var(--GW-SEPARATOR-COLOR);
  position: absolute;
  top: var(--GW-SPACING-3);
  right: var(--GW-SPACING-3);
  font-size: var(--GW-FONT-SIZE-BODY);
  padding: 0;
}

.clearIcon {
  cursor: pointer;
}

button.icon {
  cursor: pointer;
}

.zeroPadding {
  padding: 0;
}

.gwTypeaheadResults{
  --gw-typeahead-border: 1px;
  list-style: none;
  padding: 0;
  border: var(--gw-typeahead-border) solid var(--GW-BORDER-COLOR);
}
