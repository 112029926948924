@import "~@jutro/theme/assets/sass/helpers";

.topMargin {
    margin-top: 15px !important;
}

.currencyInputLength {
    width: 55%;
    margin-top: 15px !important;

    @include gw-breakpoint-down(phone) {
        width: 80%;
    }
}