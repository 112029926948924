.customHeaderStyle {
    background-color: var(--NN-HEADER-COLOR);
}

.pageContainer {
    display: block;
    align-self: center;
}

.footerContainerDiv {
    min-height: 7rem;
    border-top: 4px solid var(--GW-CONSUMER-HEADER-BORDER-BOTTOM-COLOR);
    z-index: var(--GW-Z-INDEX-4);
    box-shadow: var(--GW-SHADOW-1);
    background-color: var(--NN-FOOTER-COLOR);
}

.spanTextStyle {
    font-size: 10px;
    margin-bottom: 0px !important;
    margin-left: 8px !important
}

.spanLinkTextStyle {
    font-size: 10px;
    margin: 0px !important;
}

.footerHeaderTextStyle {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    font-size: 10px;
    margin: 6px 8px 0px !important;
}

.iconStyle {
    color: var(--GW-BRAND-COLOR-1)
}

.redBoldStyle {
    color:red !important;
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}