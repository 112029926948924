@import "~@jutro/theme/assets/sass/helpers";

.container {
    display: flex;
    align-items: center;

    > .monthDropdownSelect {
        border-radius: var(--date-picker-border-radius);
        margin-right: var(--date-picker-spacing-dropdown-margin-right);
    }

    .monthDropdownSelect div,
    .yearDropdownSelect div {
        --generic-select-control-bg-color: var(--date-picker-dropdown-select-control-bg-color);
        --generic-select-control-focus-bg-color: var(--date-picker-dropdown-select-control-focus-bg-color);
        --generic-select-control-border-radius: var(--date-picker-dropdown-select-control-border-radius);
        --generic-select-control-border-color: var(--date-picker-dropdown-select-control-border-color);
        --generic-select-option-padding-x: var(--date-picker-dropdown-select-option-padding-x);
        --generic-select-menu-bg-color: var(--date-picker-dropdown-select-menu-bg-color);
        --generic-select-control-padding-y: var(--date-picker-dropdown-select-control-padding-y);
        --generic-select-control-padding-x: var(--date-picker-dropdown-select-control-padding-x);
        --generic-select-control-min-height: var(--date-picker-dropdown-select-control-height);
        --generic-select-control-height: var(--date-picker-dropdown-select-control-height);
        max-height: 200px;
        text-align: left;

        span {
            --generic-select-select-icon-font-size: var(--date-picker-dropdown-arrow-font-size);
            --generic-select-select-icon-padding-left: 0;
        }
    }
}

.navigationIconPrev,
.navigationIconNext {
    padding: 0;
    margin: 0 var(--date-picker-spacing-navigation-margin-x);
    height: var(--date-picker-navigation-height);
    width: var(--date-picker-navigation-width);
    border: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: var(--date-picker-navigation-font-size);
    text-align: center;
    color: var(--date-picker-color);
    cursor: pointer;

    @include gw-breakpoint-down(phone) {
        margin: 0;
    }
}

.navigationIconNextDisabled,
.navigationIconPrevDisabled {
    visibility: hidden;
}

.yearDropdownSelect {
    flex: 2 1;
}

.monthDropdownSelect {
    flex: 3 1;
    margin-right: 10px;
}
