.formContainer {
    display:flex
}

.bottomMargin {
    margin-bottom: 10px;
}

.addressMapPlace {
    display:grid;
    padding-right: 10px;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
}

.phoneAddressMapPlace {
    display:grid;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
}

.addressInputSet {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.phoneAddressInputSet {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
}