.faqSection{
  padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}

.gwSectionContainer {
  margin: var(--GW-SPACING-6);
}

.gwSectionTitle {
  font-size: var(--GW-FONT-SIZE-H4);
  margin-bottom: var(--GW-SPACING-2);
  margin-top: var(--GW-SPACING-2);
}

