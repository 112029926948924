@import "../../styles/page.scss";

.searchPolicyPageContainer {
    @extend %pageContainer;
}

.searchPolicyComponentContainer {
    @extend %componentContainer;
}

.fnolSearchPolicyButton {
    float:right;
}

.marginTopContainer{
    margin-top: var(--GW-SPACING-6) ;
}

.errorText {
    margin-top: var(--GW-SPACING-6) !important;
    color:red !important;
    text-align: center !important;
}

.fnolSearchPolicyButtonDiv {
    margin-top: 15px;
    display:flex;
    justify-content: flex-end;
}
