:root {
    --gw-agent-container-margin: 0 auto;
    --gw-agent-container-max-width: 90em;
    --gw-contactagent-checkicon-height: 5em;
    --gw-contactagent-checkicon-width: 5em;
    --gw-contactagent-checkicon-margin: 3em auto;
  }
  
  .agentContainer {
    margin: var(--gw-agent-container-margin);
    max-width: var(--gw-agent-container-max-width);
  
    .confirmationCheckIcon {
      height: var(--gw-contactagent-checkicon-height);
      width: var(--gw-contactagent-checkicon-width);
      line-height: var(--GW-LINE-HEIGHT-H1);
      color: var(--GW-COLOR-SUCCESS);
      background-color: var(--GW-BACKGROUND-COLOR-BODY);
      border-radius: 50%;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .confirmationIcon {
      line-height: inherit;
      font-size: var(--GW-FONT-SIZE-H1);
    }
  
    .paraBottom {
      margin-bottom: 0;
    }
  
    .agentTitle {
      color: var(--GW-BRAND-COLOR-1);
      font-size: var(--GW-FONT-SIZE-H1);
      font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
      line-height: var(--GW-LINE-HEIGHT-H1);
      text-align: center;
      margin: 0px;
    }
  
    .contactAgentPara {
      text-align: center;
      margin: 0px;
    }
  
    .claimReference {
      font-size: var(--GW-FONT-SIZE-BODY);
      font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
      text-align: center;
      margin-bottom: var(--GW-SPACING-1);
    }
  
    .btnClass {
      --gw-btn-margin-top: 10px;
      text-align: center;
      margin-top: var(--gw-btn-margin-top);
    }
  }
  

.buttonContainer {
  display: flex;
  justify-content: center;
}