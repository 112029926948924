@import "~@jutro/theme/assets/sass/helpers";

.horizontalRadioButton {
    div {
        display: flex;
        margin-right: 50px;
        flex-flow: wrap;
        justify-content: flex-start;
    }
    margin-bottom: 10px;
}

.divider {
    margin: 20px 0px !important;
    border-top: solid 3px var(--GW-BRAND-COLOR-1) !important;
}

h1 {
    color: var(--GW-BRAND-COLOR-1) !important;
}

// Style overrides for DatePicker and CustomDatePicker
.react-datepicker-popper {
    z-index: 2000 !important;
}

.react-datepicker__day-name, .react-datepicker__day {
    @include gw-breakpoint-down(tablet) {
        margin: 0 0.1rem !important;
    }
}

// -------------------------- ANTD TimePicker styles --------------------------

.ant-picker-time-panel-cell-selected {
    div {
        background: var(--GW-BRAND-COLOR-1) !important;
    }
}

.ant-btn-primary {
    background-color: var(--GW-BRAND-COLOR-1) !important;
}

.ant-picker-now-btn {
    color: var(--GW-BRAND-COLOR-1) !important;
}

.ant-picker-focused {
    border: 1px solid darkgrey !important; 
    box-shadow: 0 0 0 0.4px rgba(128, 128, 128, 1);

    :hover {
        border-color: grey !important;
        box-shadow: none !important;
    }
}

.ant-picker {
    :hover {
        border-color: grey !important;
        box-shadow: none !important;
    }
}
    
.ant-picker:hover {
        border-color: grey !important;
        box-shadow: none !important;
    }