@import "~@jutro/theme/assets/sass/helpers";

.topMargin {
  margin-top: 10px !important;
}

.body {
    max-width: 35vw;

    @include gw-breakpoint-down(phone) {
        max-width: 80vw;
      }
}

.header {
    text-align: center;
    margin: 20px 5px !important;
}

.buttonsContainerDiv {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}

.blankButtonContainer {
  width: 48%;
  background-color: transparent !important;
  color: black;
}

.selectedButtonContainer {
  width: 48%;
  color: black;
}

.iUnderstandButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0px;
}

