.topBottomMargin {
    margin: 12px 0px !important;
}

.checkGarageContainer {
    display: flex;
}

.checkGarageButton {
    margin: 10px;
}

.greenText {
    color: green !important;
    display: flex;
    align-items: center;
}

.redText {
    color: red !important;
    display: flex;
    align-items: center;
}