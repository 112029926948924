:root{
	--gw-damage-picker-width: 152px;
	--gw-damage-picker-height: 327px;
	--gw-car-part-z-index-trunk-side: 1;
	--gw-car-part-z-index-car-part: 2;
	--gw-car-part-z-index-rear-roof: 3;

	--gw-car-part-left-corner-left: 23px;
	--gw-car-part-left-corner-top: 5px;	

	--gw-car-part-bumper-front-left: 42px;
	--gw-car-part-bumper-front-top: 0px;	

	--gw-car-part-right-corner-top: 5px;	
	--gw-car-part-right-corner-left: 111px;	

	--gw-car-part-left-side-left: 12px;
	--gw-car-part-left-side-top: 25px;

	--gw-car-part-hood-top: 25px;
	--gw-car-part-hood-left: 42px;	

	--gw-car-part-windshield-left: 19px;
	--gw-car-part-windshield-top: 93px;

	--gw-car-part-right-side-left: 112px;
	--gw-car-part-right-side-top: 25px;

	--gw-car-part-left-door-left: 12px;
	--gw-car-part-left-door-top: 113px;

	--gw-car-part-roof-left: 36px;
	--gw-car-part-roof-top: 148px;

	--gw-car-part-right-door-left: 113px;
	--gw-car-part-right-door-top: 113px;

	--gw-car-part-left-door-rear-left: 12px;
	--gw-car-part-left-door-rear-top: 184px;

	--gw-car-part-right-door-rear-left: 113px;
	--gw-car-part-right-door-rear-top: 184px;

	--gw-car-part-left-side-rear-left: 13px;
	--gw-car-part-left-side-rear-top: 266px;

	--gw-car-part-left-corner-rear-left: 23px;
	--gw-car-part-left-corner-rear-top: 313px;

	--gw-car-part-windshield-rear-left: 19px;
	--gw-car-part-windshield-rear-top: 230px;

	--gw-car-part-right-side-rear-left: 110px;
	--gw-car-part-right-side-rear-top: 267px;
	
	--gw-car-part-trunk-top: 280px;
	--gw-car-part-trunk-left: 43px;

	--gw-car-part-bumper-rear-left: 43px;
	--gw-car-part-bumper-rear-top: 313px;

	--gw-car-part-right-corner-rear-left: 110px;
	--gw-car-part-right-corner-rear-top: 313px;
}
.gw_damage_picker {
	margin: 0px;
	width: var(--gw-damage-picker-width);
	height: var(--gw-damage-picker-height);
	position: relative;
	text-align: center;
}
.gw_car_part {
	line-height: 0px !important;
	position: absolute;
	z-index: var(--gw-car-part-z-index-car-part);	
	cursor: pointer;
	&.gw-left-corner {
		left: var(--gw-car-part-left-corner-left);
		top: var(--gw-car-part-left-corner-top);	
	}
	&.gw-bumper {
		left: var(--gw-car-part-bumper-front-left);
		top: var(--gw-car-part-bumper-front-top);
	}
	&.gw-right-corner {
		left: var(--gw-car-part-right-corner-left);
		top: var(--gw-car-part-right-corner-top);
	}
	&.gw-hood {
		left: var(--gw-car-part-hood-left);
		top: var(--gw-car-part-hood-top);
	}
	&.gw-right-side {
		left: var(--gw-car-part-right-side-left);
		top: var(--gw-car-part-right-side-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-left-side {
		left: var(--gw-car-part-left-side-left);
		top: var(--gw-car-part-left-side-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-windshield {
		left: var(--gw-car-part-windshield-left);
		top: var(--gw-car-part-windshield-top);
	}
	&.gw-left-door {
		left: var(--gw-car-part-left-door-left);
		top: var(--gw-car-part-left-door-top);
	}
	&.gw-right-door {
		left: var(--gw-car-part-right-door-left);
		top: var(--gw-car-part-right-door-top);
	}
	&.gw-roof {
		left: var(--gw-car-part-roof-left);
		top: var(--gw-car-part-roof-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-left-door-rear {
		left: var(--gw-car-part-left-door-rear-left);
		top: var(--gw-car-part-left-door-rear-top);
	}
	&.gw-right-door-rear {
		left: var(--gw-car-part-right-door-rear-left);
		top: var(--gw-car-part-right-door-rear-top);
	}
	&.gw-windshield-rear {
		left: var(--gw-car-part-windshield-rear-left);
		top: var(--gw-car-part-windshield-rear-top);
	}
	&.gw-left-side-rear {
		left: var(--gw-car-part-left-side-rear-left);
		top: var(--gw-car-part-left-side-rear-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-right-side-rear {
		left: var(--gw-car-part-right-side-rear-left);
		top: var(--gw-car-part-right-side-rear-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-trunk {
		left: var(--gw-car-part-trunk-left);
		top: var( --gw-car-part-trunk-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-bumper-rear {
		left: var(--gw-car-part-bumper-rear-left);
		top: var(--gw-car-part-bumper-rear-top);
	}
	&.gw-left-corner-rear {
		left: var(--gw-car-part-left-corner-rear-left);
		top: var(--gw-car-part-left-corner-rear-top);
	}
	&.gw-right-corner-rear {
		left: var(--gw-car-part-right-corner-rear-left);
		top: var(--gw-car-part-right-corner-rear-top);
	}
}
.gw_vehicle_collision{
    display: inline-block;
}
.gw_car {
    display: inline-block;
}
.gwCarContainer {
	display: inline-block;
}
