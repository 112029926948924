.headingStyle {
    font-weight: 400 !important;
    margin-bottom: 7px !important;
}

.indentStyle {
    margin-left: 30px;
    margin-bottom: 20px;
}

.myPropertyContainerStyle {
    display: flex;
    margin-bottom: 10px;
}