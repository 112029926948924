.headingStyle {
    font-weight: 400 !important;
    margin-bottom: 7px !important;
}

.indentStyle {
    margin-left: 30px;
    margin-bottom: 20px;
}

.checkBoxesMargin {
    margin-top: 13px;
}

.otherSpecify {
    margin-top: 13px;   
    margin-left: 30px;
}