@import "../../styles/page.scss";

.landingPageContainer {
    @extend %pageContainer;
    max-width: 80%;
    text-align: -webkit-center;

    @include gw-breakpoint-only(phone) {
        max-width: 95%;
    }
}

.landingPageComponentContainer {
    @extend %componentContainer;
}

.topBottomMargin {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
}

.radioButtonContainer {
    max-width: 96%;
    display: flex;
    align-items: center;
    margin: 30px 0px;
}

.fnolGDPRConsentDiv{
    display: flex;
    align-items: center ;
    margin-top: var(--GW-SPACING-6);
    text-align: start;
}

.fnolGDPRCheckbox{
    padding: 10px;
}

.nextPageButtonDiv {
    margin-top: 15px;
    display:flex;
    justify-content: flex-end;
}

.imageStyle {
    max-width: 20% !important;

    @include gw-breakpoint-only(phone) {
        max-width: 35% !important;
    }
}

.singleValueText {
    font-size: x-large !important;
    font-weight: 600 !important;
    color: var(--NN-THEMED-TITLE-COLOR) !important;
    margin: 40px 15px !important;
}