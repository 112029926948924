@import "~@jutro/theme/assets/sass/helpers";

.nnLogoStyle {
    max-width: 80px !important;

    @include gw-breakpoint-down(tablet) {
        max-width: 60px !important;
    }
}

.footerHeaderTextStyle {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    font-size: 10px;
    margin: 6px 8px 0px !important;
}

.spanTextStyle {
    font-size: 10px;
    margin-bottom: 0px !important;
    margin-left: 8px !important
}