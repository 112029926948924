.quickFlowPageContainer {
    margin: auto 7%;
}

.centerAlignedNoMargin {
    align-items: center !important;
    margin: 0px auto !important;
    text-align: center;
}

.phoneCenterAlignedWithMargin {
    align-items: center !important;
    margin: 0px auto !important;
    text-align: center;
    margin-bottom: 15px !important;
}

.centerAlignedWithMargin {
    align-items: center !important;
    margin-top: 20px !important;
    color: red !important;
    text-align: center;
}

.fnolQuickFlowPage {
    align-items: center;
    justify-content: center;
}

.fnolQuickFlowCenterDiv {
    padding: 30px 0px;
    margin-left: auto;
    margin-right: auto;
}

.fnolQuickFlowUrgentCallButton {
    margin-left: 20px;
    margin-top: 5px;
}

.halfWidthWithBottomMargin {
    margin-bottom: 9px;
    width: 49%;
}

.halfWidthWithMargin {
    margin-bottom: 8px;
    width: 70.7%;
}

.fnolQuickFlowSubmitButton {
    float: right;
}

.bottomMargin {
    margin-bottom: 12px !important;
}

.topMargin {
    margin-top: 12px !important;
}

.flexJustify {
    display: flex;
    justify-content: space-between;
}

.phoneWidthWithMargin {
    margin-bottom: 9px;
}

.quickFlowPageHorizontal {
    #fnolQuickFlowPolicyType {
        display: flex;
        margin-right: 50px;
        flex-flow: wrap;

        div {
            display: flex;
            margin-right: 50px;
            flex-flow: wrap;
        }
    }
    margin-bottom: 10px;
}