.lossAndDamageHeaderClass{
    padding-top: 10px;
}

.detailsContainer {
    margin-left: 40px;
}

.checkBoxContainer {
    max-width: 300px;
    margin-bottom: 10px;
    margin-left: 20px;    
}

.otherDamageContainer {
    max-width: 300px;
    margin-left: 60px;
}

.propertyDamage {
    margin-bottom: 20px !important;
}