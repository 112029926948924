 .canIdentifyAndDelete {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px !important;
 }

 .deleteButton {
     border: none;
 }
  
 .marginBotton {
     margin-bottom: 10px !important;
 }