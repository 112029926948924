.checkAndTextDiv {
    display: flex;
}

.checkBoxContainer {
    max-width: 300px;
    margin-bottom: 10px;
}

.otherDamageContainer {
    max-width: 300px;
    margin-left: 10px;
}