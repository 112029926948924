@import "~@jutro/theme/assets/sass/helpers";

.firstLastName {
    width: 49%;

    @include gw-breakpoint-down(phone) {
        width: 90%;
        margin-bottom: 8px;
    }
}

.halfWidthWithMargin {
    margin-bottom: 8px;
    width: 60%;

    @include gw-breakpoint-down(phone) {
        width: 90%;
        margin-bottom: 8px;
    }
}

.fullDropdownWidth {
    margin-bottom: 8px;
    width: 98%;

    @include gw-breakpoint-down(phone) {
        width: 90%;
        margin-bottom: 8px;
    }
}

.injuryInvolvement {
    margin-bottom: 8px !important;
}

.doubleInputContainer {
    display: flex;
    width: 100%;
    margin: 10px 0px;
    justify-content: space-between;

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.flexContainer {
    display: flex;
}

.redText {
    color: red !important;
    display: flex;
    align-items: center;
    margin-left: 10px;
}