.icon {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;

    i {
        position: relative;
        width: auto;
        color: var(--GW-TOOLTIP-ICON-COLOR) !important;
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
    }
    
}
