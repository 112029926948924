@import "~@jutro/theme/assets/sass/helpers";

.clause {
    --clause-background-color: var(--GW-BACKGROUND-COLOR);
    --clause-padding: 24px;

    @include gw-breakpoint-down(phone) {
        --clause-padding: 1rem;
    }

    background-color: var(--clause-background-color);
    padding: var(--clause-padding);
    text-align: left;
}

.clauseNoPadding {
    padding: 0;
}

.clauseAndTermSpacing {
    --gw-clause-component-spacing: 15px;
    margin-bottom: var(--gw-clause-component-spacing);
}

// Not Editable Styles
.readOnlyGrid {
    --clause-margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);

    &:not(:last-child) {
        --read-only-clause-margin: 16px;
        --read-only-clause-boder-size: 1px;
        margin-bottom: var(--read-only-clause-margin);
        border-bottom: var(--read-only-clause-boder-size) solid var(--GW-BORDER-COLOR);
    }
}

.readOnlyGridSlotLeft {
    grid-area: left;
}

.readOnlyGridSlotRight {
    grid-area: right;
}

.readOnlyGridAmount {
    --read-only-clause-amount-margin: 5px;
    margin-left: var(--read-only-clause-amount-margin);
}

.clauseTermReadonly {
    grid-template-columns: 3fr 1fr;
}