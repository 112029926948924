.stepContainer {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.navigationButton {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border: 0;
    padding: 0;
}

.step {
    margin-right: var(--GW-SPACING-2);

    &::before {
        content: '';
        display: inline-block;
        min-width: var(--GW-SPACING-2);
        height: var(--GW-SPACING-2);
        background-color: var(--GW-BRAND-COLOR-1);
        border-radius: 50%;
        vertical-align: middle;
    }
}

.notVisited::before {
    background-color: var(--GW-SEPARATOR-COLOR);
}

.active::before {
    background-color: var(--GW-BRAND-COLOR-2);
}
