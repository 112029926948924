@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-wizard-side-page-category-padding: 10px;
    --gw-wizard-side-page-separator-margin: 3px;
    --gw-wizard-side-page-separator-height: 18px;
    --gw-wizard-side-page-separator-border: 1px;
    --gw-wizard-side-page-tile-padding: 12px;
}

.gwWizardPageTitle {
    margin: 0 0 var(--GW-SPACING-6) 0;

    .gwWizardPageCategory {
        font-size: var(--GW-FONT-SIZE-BODY);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        margin-bottom: var(--GW-SPACING-2);
        line-height: var(--GW-LINE-HEIGHT-BODY);
    }

    .gwWizardPageAssociated {
        color: var(--GW-LINK-COLOR);
        text-decoration: none;
        margin-left: var(--GW-SPACING-1);
    }
}

.gwWizardSidePageTitle {
    display: flex;

    .gwWizardSidePageCategory {
        padding-right: var(--gw-wizard-side-page-category-padding);
    }

    .gwWizardSidePageAssociated {
        color: var(--GW-FOCUS-COLOR);
        text-decoration: none;
    }

    .gwWizardSidePageTitleSeparator {
        margin-top: var(--gw-wizard-side-page-separator-margin);
        height: var(--gw-wizard-side-page-separator-height);
        border: var(--gw-wizard-side-page-separator-border) solid var(--GW-BACKGROUND-COLOR-BODY);
    }

    .gwWizardSidepageTitle {
        padding-left: var(--gw-wizard-side-page-tile-padding);
    }
}

.gwPageTitle {
    color: var(--GW-BRAND-COLOR-2);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include gw-breakpoint-up(tablet) {
        flex-direction: row;
    }
}

h1[class*='gwPageTitle'] {
    margin-bottom: var(--GW-SPACING-6);
}
