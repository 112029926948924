@import "~@jutro/theme/assets/sass/helpers";

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.topBottomMargin {
    margin: 20px 0px !important;
}

.leftMargin {
    margin-left: 10px;
}

.bottomMargin {
    margin-bottom: 10px !important;
}

.widthWithMarginBottom {
    margin-bottom: 10px !important;
    width: 45%;

    @include gw-breakpoint-only(phone) {
        margin-bottom: 10px !important;
        width: 80%;
      }
}

.selectedFacilityContainer {
    border: 1px solid var(--GW-BORDER-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
    padding: 10px 20px;
    margin-bottom: 15px;
    display: inline-block
}

.rightMargin {
    margin-right: 10px;
}

.repairerDetailsContent {
    display: flex;
}

.searchCriteriaStyle {
    display: flex;
    justify-content: space-between;
}

.fnolSearchPolicyButton {
    margin:7px 0px;
}