.carouselContainer {
    position: relative;
}

.carouselTextContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    .header, .description {
        text-shadow: 0 1px 2px var(--GW-COLOR-WARNING-CONTRAST);
        color: var(--GW-COLOR-INFO-CONTRAST);
    }
}