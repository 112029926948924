.dropdownStyle {
    width: 68%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.phoneDropdownStyle {
    width: 90%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.marginTop {
    margin-top: 5px;
}