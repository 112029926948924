@import "~@jutro/theme/assets/sass/helpers";

html {
  --gw-header-image: url("../../public/branding/nnLogo2.png");
  --gw-mobile-header-image: url("../../public/branding/nnLogo2.png");

  .gw-header-img {
    @include gw-breakpoint-down(tablet) {
      --gw-header-image-height: 35px;
      --gw-header-image-width: 100px;
    }
  }

  .gw-header {
    --gw-header-image-height: 35px;
    background-color: var(--GW-COLOR-BACKGROUND-2);
  }
}

.fnolMain {
  background-color: var(--GW-COLOR-BACKGROUND-1);
  min-height: 100vh;
}

.fnolMainContent {
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-auto-columns: 100%;

  &::before, &::after {
    display: none;
  }
}

.page {
  --gw-amp-page-width: 80%;
  --gw-amp-page-margin-bottom: 50px;
  --gw-amp-page-padding-top: 40px;

  @include gw-breakpoint-only(tablet) {
    --gw-amp-page-width: 95%;
  }

  @include gw-breakpoint-only(phone) {
    --gw-amp-page-width: auto;
    --gw-amp-page-padding-top: 0;
  }

  width: var(--gw-amp-page-width);
  margin: auto;
  margin-bottom: var(--gw-amp-page-margin-bottom);
  padding-top: var(--gw-amp-page-padding-top)
}

.ampMain {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
}

// theme
$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ------------------------------------
//   COMMON CSS MODULES
// ------------------------------------
@import "~@jutro/layout/hashedStyles";
@import "~@jutro/components/hashedStyles";
@import "~@jutro/router/hashedStyles";
@import "~@jutro/floorplan/hashedStyles";
@import "~@jutro/datatable/hashedStyles";
@import "~@jutro/theme/hashedStyles";

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.jut__ApplicationHeader__subApplicationHeader {
  height: auto;
}

div[class*='wizardContainer'] {
  @include gw-breakpoint-down(tablet) {
    --wizard-template-width-desktop: 100%;
  }
}

.jut__AppFloorPlan__main {
  padding: 0;
  margin: var(--GW-SPACING-4) var(--GW-SPACING-3) var(--GW-SPACING-6);
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

@import "./common.scss";