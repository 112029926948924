@import "~@jutro/theme/assets/sass/helpers";

.ingLinkStyles {
    text-decoration: auto;
    color: black;
    font-size: 12px;

    @include gw-breakpoint-down(tablet) {
        font-size: 8px;
    }
}

.ingLogosStyle {
    max-height: 40px !important;

    @include gw-breakpoint-down(tablet) {
        max-height: 25px !important;
    }
}

.insuredByStyle {
    margin: 0px 5px;
}

.logoContainer {
    display: flex;
    align-items: baseline;
}