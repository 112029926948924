.fnolContactCallCenterPage {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fnolContactCallCenterDiv {
    padding:30px;
    margin-left: auto;
    margin-right: auto;
}

.fnolContactCallCenterUrgentCallButton {
    margin-left: 20px;
    margin-top: 5px;
}

.red {
    color:red !important;
}