.pageContainer {
    min-width: 100%;
    display: flex;
    justify-content: center;
}

.itemsContainer {
    max-width: 60%;
}

.phoneItemsContainer {
    max-width: 85%;
}

.textHeader {
    margin-bottom: 15px !important;
}

.dividerStyling {
    margin: 8px 0px !important;
    border-top: solid 2px var(--GW-BRAND-COLOR-1) !important;
}