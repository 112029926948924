.questionSetsContainer {
    margin: 7px 0px;
}

.bottomMargin {
    margin-bottom: 15px;
}

.smallBottomMargin {
    margin-bottom: 7px !important;
}

.leftTopMargin {
    margin-left: 10px;
    margin-top: 5px;
    width: 50%;
}