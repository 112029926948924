.gwIssuesContainer {
  --gw-issues-message-radius: 2px;
  --gw-issues-message-border: 100%;
  --gw-issues-message-flex: 1;
  --gw-issues-icon-border: 1px;
  margin-bottom: var(--GW-SPACING-5);

  .gwIssuesMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--gw-issues-message-radius);

    &::before {
      margin: var(--GW-SPACING-4);
      display: block;
      font-family: "FontAwesome";
      line-height: calc(2 * var(--GW-LINE-HEIGHT-BODY));
      font-size: var(--GW-FONT-SIZE-H4);
      flex: none;
      text-align: center;
      height: calc(2 * var(--GW-LINE-HEIGHT-BODY));
      width: calc(2 * var(--GW-LINE-HEIGHT-BODY));
      border-radius: var(--gw-issues-message-border);
    }
  }

  .gwIssuesError {
    border: var(--gw-issues-icon-border) solid var(--GW-SEPARATOR-COLOR);
    background-color: var(--GW-BACKGROUND-COLOR-BODY);

    &::before {
      content: var(--icon-font-awesome-error);
      color: var(--GW-COLOR-ERROR);
      background-color: var(--GW-SEPARATOR-COLOR);
    }
  }

  .gwIssuesWarning {
    border: var(--gw-issues-icon-border) solid var(--GW-COLOR-WARNING);
    background-color: var(--GW-COLOR-WARNING);

    &::before {
      content: var(--icon-font-awesome-warning);
      color: var(--GW-COLOR-WARNING);
      background-color: var(--GW-COLOR-WARNING);
    }
  }

  .gwIssuesMessageContainer {
    flex: var(--gw-issues-message-flex);
    padding-left: var(--GW-SPACING-8);
  }

  .gwIssuesCloseIcon {
    margin: var(--GW-SPACING-4);
    align-self: flex-start;
    color: var(--GW-TEXT-COLOR-2);
    font-size: var(--GW-FONT-SIZE-H3);
    cursor: pointer;
  }

  .gwIssuesCloseIcon:hover {
    color: var(---GW-TEXT-COLOR-1);
    background-color: var(--GW-COLOR-WARNING);
  }
}
