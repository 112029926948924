@import "~@jutro/theme/assets/sass/helpers";

.firstLastName {
    width: 49%;

    @include gw-breakpoint-down(phone) {
        width: 80%;
    }
    margin-bottom: 10px;
}

.injuryInvolvement {
    margin-bottom: 8px !important;
}

.flexContainer {
    display: flex;
}

.redText {
    color: red !important;
    display: flex;
    align-items: center;
    margin-left: 10px;
}