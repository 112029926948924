.pageContainer {
    padding: 20px 15px;
    display: block;
    align-self: center;
}

.footerContainerDiv {
    min-height: 7rem;
    border-top: 4px solid var(--GW-CONSUMER-HEADER-BORDER-BOTTOM-COLOR);
    z-index: var(--GW-Z-INDEX-4);
    box-shadow: var(--GW-SHADOW-1);
    background-color: var(--NN-FOOTER-COLOR);
    padding: 5px;
}
