.firstLastName {
    width: 49%;
}

.phoneFirstLastName {
    margin-bottom: 8px;
}

.halfWidthWithMargin {
    margin-bottom: 8px;
    width: 60%;
}

.phoneHalfWidthWithMargin {
    margin-bottom: 8px;
}

.injuryInvolvement {
    margin-bottom: 8px !important;
}

.emailPhoneContainer {
    display: flex;
    width: 100%;
    margin: 10px 0px;
    justify-content: space-between;
}

.flexContainer {
    display: flex;
}

.redText {
    color: red !important;
    display: flex;
    align-items: center;
    margin-left: 10px;
}