.oneDocumentDiv {
    border: solid 1px var(--GW-BRAND-COLOR-2);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    max-width: 800px;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 30% fr;
}

.docTypeSelect {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1; 

}

.fileUploadComponent {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;      
}

.deleteDocIcon {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: span 2;       
    align-self: center;
    justify-self: center;

}

.documentListDiv {
    display: block;
    text-align: left;
}

.docsDiv {
    margin-bottom: 50px;
}

.pError {
    color: red;
    font-weight: bold;
    white-space: pre-wrap;
}